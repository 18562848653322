/**
 *
 * Custom function
 *
 */

(function ($) {

	/*
	 *	Funzione per rendere un box tutto cliccabile. ATTENZIONE: all'interno del box deve esserci un unico link
	 */
	function allClick() {
		var clickbox = $('.allclick');
		clickbox.each(function () {
			var $this = $(this);
			$this.css('cursor', 'pointer');
			$this.find('a').click(function (event) {
				event.preventDefault();
			});

			if ($this.find('a').attr('target') == '_blank') {
				$this.click(function () {
					window.open($('a', this).attr('href'));
				});
			} else {
				$this.click(function () {
					window.location.href = $('a', this).attr('href');
				});
			}
		});
	}

	/*
	 *	Caricamento ajax delle immagini dal marcatore [data-loadimg] e dei background dal marcatore [data-loadbackground]
	 */
	function loadAsynchronousImage() {

		$('*[data-loadimg]').each(function () {
			var box = $(this),
				src = box.attr('data-loadimg'),
				alt = '',
				img = new Image();

			if (box.attr('data-alt')) {
				alt = box.attr('data-alt');
			}

			$(img).on('load', function () {
				box.append('<img src="' + src + '" alt="' + alt + '" width="' + img.width + '" height="' + img.height + '">');
				$('img', box).hide().fadeIn();

			}).attr('src', src);
		});

		$('*[data-loadbackground]').each(function () {
			var box = $(this),
				src = box.attr('data-loadbackground');

			box.css({
				'background-image': 'url(' + src + ')',
				'background-repeat': 'no-repeat',
				'background-position': 'top center'
			});

			if (box.has(['data-position'])) {
				box.css({
					'background-position': box.attr('data-position'),
					'background-image': 'url(' + src + ')',
					'background-repeat': 'no-repeat'
				});
			} else {
				box.css({
					'background-position': 'top center',
					'background-image': 'url(' + src + ')',
					'background-repeat': 'no-repeat'
				});
			}

			if (box.attr('data-repeat') == 'repeat') {
				box.css({
					'background-repeat': 'repeat'
				});
			} else {
				box.css({
					'background-repeat': 'no-repeat'
				});
			}
		});
	}

	/*
	 *	Funzione per la gestione dei bottoni "condividi" legati ai social
	 */
	function socialOpen() {
		$('[data-socialurl]').each(function () {
			var $this = $(this),
				url = $this.attr('data-socialurl');

			$this.click(function () {
				window.open(url, '', 'width=500, height=500');
			});

		});
	}

	/*
	 *	Funzione per l'apertura di una finestra modale tramite cookie
	 */
	function openModal() {
		if ($('#onloadmodal').length) {
			if (!$.cookie("openmodal")) {
				$('#onloadmodal').modal();
				$.cookie("openmodal", 'no', {
					path: '/'
				});
			}
		}
	}

	/*
	 *	Funzione per l'apertura di un iframe all'interno di una finestra modale
	 */
	function iframeModalOpen() {
		$('.videoModal').each(function () {
			// impostiamo gli attributi da aggiungere all'iframe es: data-src andrà ad impostare l'url dell'iframe
			$('.modalButton').on('click', function (e) {
				var src = $(this).attr('data-src');
				var width = $(this).attr('data-width') || 640; // larghezza dell'iframe se non impostato usa 640
				var height = $(this).attr('data-height') || 360; // altezza dell'iframe se non impostato usa 360

				var allowfullscreen = $(this).attr('data-video-fullscreen'); // impostiamo sul bottone l'attributo allowfullscreen se è un video per permettere di passare alla modalità tutto schermo

				// stampiamo i nostri dati nell'iframe
				$(".videoModal iframe").attr({
					'src': src,
					'height': height,
					'width': width,
					'allowfullscreen': ''
				});
			});

			// se si chiude la modale resettiamo i dati dell'iframe per impedire ad un video di continuare a riprodursi anche quando la modale è chiusa
			$(this).on('hidden.bs.modal', function () {
				$(this).find('iframe').html("");
				$(this).find('iframe').attr("src", "");
			});
		});
	}

	/*
	 *	Funzione per la formattazione delle tabelle con classe listino
	 *	tramite le classi di bootstrap
	 */
	function responsiveTable() {

		$('.entry-content table').each(function () {
			var $this = $(this);
			$this.addClass('table');
			$this.wrap('<div class="table-responsive"></div>');
		});

		if ($(window).width() <= 767) {

			$('#primary .table-responsive').prepend(
				'<span class="angle-scroll-left">' +
				'<i class="icon icon-edt-arrow-right-6 angle-scroll-left__animation"></i>' +
				'</span>'
			);

			$('#primary .table-responsive').on('scroll', function (event) {
				var angleScrollLeft = $('.angle-scroll-left');

				if ($(this).scrollLeft() >= 1) {
					angleScrollLeft.addClass('fade-out');
				} else {
					angleScrollLeft.removeClass('fade-out');
				}
			});

			$('#primary').find('.table-responsive').each(function () {
				var table = $(this);
				$('.angle-scroll-left').click(function (event) {
					table.animate({
						scrollLeft: '+=60'
					}, 200);
				});
			});
		}
	}

	/*
	 *	Funzione per l'inizializzazione della mappa
	 */
	function initializeGmap() {
		$('*[data-location]').each(function () {
			var map = $(this),
				address = map.attr('data-location');
			map.setGMap({
				address: address
			});
		});
	}

	/*
	 * Funzione per l'attivazione del menu responsive
	 */
	function responsiveMenu() {
		$('.main-navigation').find('a[href="#"]').each(function () {
			$(this).click(function (event) {
				event.preventDefault();
			});
		});

		var menuPrimaryOffcanvas = $("#js-menu-primary-offcanvas");

		$('.main-navigation').find('a[href="#"]').each(function () {
			$(this).click(function (event) {
				$('ul.sub-menu').removeClass('open');
				$(this).next().toggleClass('open');
			});
		});

		$('.open-menu').click(function () {
			menuPrimaryOffcanvas.addClass('offcanvas-open');
			$('body').addClass('menu-open');
			//$('html').addClass('no-scroll');
		});

		$('.close-menu').click(function () {
			menuPrimaryOffcanvas.removeClass('offcanvas-open');
			$('body').removeClass('menu-open');
			//$('html').removeClass('no-scroll');
		});
	}

	/*
	 * Funzione per nascondere e visualizzare la testata allo scroll della pagina
	 */
	function scrollHeader() {
		$('#js-header').scrollHide({
			scrollDelta: 10,
			scrollOffset: 110,
			scrollClass: 'scrh-header',
			scrollClassHide: 'scrh-hide',
			onScroll: function (context, msg, currentTop) {
				if (currentTop !== 0) {
					$('.menu-offcanvas').removeClass('offcanvas-open');
				}

				if (currentTop === 0) {
					$('.menu-offcanvas').removeClass('offcanvas-open');
				}

			}
		});
	}

	/*
	 * Funzione per gestire il fake form allo scroll della pagina
	 */
	function scrollFake() {
		var formFake = $('#fake-form'),
			button = $('#fixed-cta'),
			menu = $('#site-menu-2');

		if ($(window).width() > 991 && formFake.length > 0) {

			// se esiste il form e non siamo a livello mobile (smartphones)

			var position = (formFake.offset().top + formFake.outerHeight()) - menu.outerHeight();

			$(window).scroll(function () {
				if ($(this).scrollTop() >= position) {

					button.addClass('reveal');

				} else {

					button.removeClass('reveal');
				}
			});

		}
	}

	/*
	 * Funzione per sostituire i br con spazio 
	 * sotto un certo breakpoint
	 */
	function replaceBr(selector, breakpoint) {
		if ( $(window).width() < breakpoint ) {
			$(selector).find('br').replaceWith(' ');
		}
	}

	/*
	 *  Funzioni per PhotoSwiper
	 */
	function addImageSizeForPhotoSwiper() {
		var thumbs = $('img[data-big-size]');
		thumbs.each(function (index) {
			if ($(this).parent('a').length > 0) {
				var isImageLink = $(this).parent('a').attr('href').match(/\.(jpeg|jpg|gif|png)$/);
				if (isImageLink) {
					big_size = $(this).data('big-size');
					link = $(this).parent('a');
					link.attr('data-size', big_size);
					link.wrap('<div class="single-image"></div>');
				}
			}
		});
	}

	function initPhotoSwiper(selector) {
		var galleryItems = $(selector);
		var pswpItems = [];
		galleryItems.each(function (index) {
			var dimensions = $(this).find('a').data('size').split('x');
			var width = parseInt(dimensions[0], 10);
			var height = parseInt(dimensions[1], 10);

			myImage = {
				src: $(this).find('a').attr('href'),
				w: width,
				h: height,
				msrc: $(this).find('img').attr('src')
			};

			pswpItems.push(myImage);
		});


		galleryItems.each(function (index) {
			$(this).click(function (evt) {
				evt.preventDefault();
				openPhotoSwipe(pswpItems, index);
			});
		});
	}

	function openPhotoSwipe(galleryItems, index) {
		var options = {
			index: index
		};
		var pswpElement = $('.pswp')[0];

		var gallery = new PhotoSwipe(pswpElement, PhotoSwipeUI_Default, galleryItems, options);
		gallery.init();
	}


	function smoothScroll() {
		$("a[href*='#']").not("[href='#'],[data-toggle]").click(function () {
			if (location.pathname.replace(/^\//, '') == this.pathname.replace(/^\//, '') && location.hostname == this.hostname) {
				var target = $(this.hash);
				target = target.length ? target : $('[name=' + this.hash.slice(1) + ']');
				if (target.length) {

					if (target.attr('data-paddingTopScroll')) {
						var adjust = target.attr('data-paddingTopScroll');
					}
					else {
						var adjust = target.css('padding-top') ? target.css('padding-top').replace('px', '') : 0;
					}

					$('html, body').animate({
						scrollTop: target.offset().top - adjust
					}, 1000);

					return false;
				}
			}
		});
	}

	function startOwl(selector) {
		$(selector).each(function (arguments) {
			var owl = $(this);

			var color = owl.data('owl-color');
			var shadow = owl.data('owl-shadow');
			var start = owl.data('owl-start-position');

			if (owl.data('owl-color') === undefined) color = '#ff5d1b';
			if (owl.data('owl-shadow') === undefined) shadow = 'rgba(0,0,0,.15)';
			if (owl.data('owl-start-position') === undefined) start = 0;

			var params = {
				nav: owl.data('owl-navigation'),
				navSpeed: owl.data('owl-slide-speed'),
				autoplay: owl.data('owl-autoplay'),
				autoplayHoverPause: owl.data('owl-autoplay-hover-pause'),
				autoplayTimeout: owl.data('owl-autoplay-timeout'),
				autoplaySpeed: owl.data('owl-autoplay-speed'),
				dots: owl.data('owl-dots'),
				dotsSpeed: owl.data('owl-dots-speed'),
				loop: owl.data('owl-loop'),
				center: owl.data('owl-center'),
				autoWidth: owl.data('owl-auto-width'),
				margin: owl.data('owl-margin'),
				startPosition: start,
				autoHeight: false,
				lazyLoad: true,
				rtl: owl.data('owl-rtl'),
				animateOut: owl.data('owl-animation-out'),
				animateIn: owl.data('owl-animation-in'),
				navText: [
					'<svg xmlns="http://www.w3.org/2000/svg" width="46" height="83" viewBox="0 0 46 83"><path d="M42.05,8.28A3.05,3.05,0,1,0,37.73,4L.89,40.87a3.06,3.06,0,0,0,0,4.32L37.73,82.1a3.05,3.05,0,1,0,4.32-4.32L7.37,43Z" transform="translate(0 0)" fill="' + shadow + '"/><path d="M45.11,5.22A3.05,3.05,0,1,0,40.79.9L4,37.81a3.06,3.06,0,0,0,0,4.32L40.79,79a3.05,3.05,0,0,0,4.32-4.32L10.42,40Z" transform="translate(0 0)" fill="' + color + '"/></svg>',
					'<svg xmlns="http://www.w3.org/2000/svg" width="46" height="83" viewBox="0 0 46 83"><path d="M35.58,43,.89,77.78A3.05,3.05,0,0,0,5.21,82.1L42.05,45.19a3.06,3.06,0,0,0,0-4.32L5.21,4A3.05,3.05,0,0,0,.89,8.28Z" transform="translate(0 0)" fill="' + shadow + '"/><path d="M38.63,40,4,74.72A3.05,3.05,0,0,0,8.27,79L45.11,42.13a3.06,3.06,0,0,0,0-4.32L8.27.9A3.05,3.05,0,0,0,4,5.22Z" transform="translate(0 0)" fill="' + color + '"/></svg>'
				]
			};

			if (owl.data('owl-nav-prev') && owl.data('owl-nav-next')) {
				params['navText'] = [
					owl.data('owl-nav-prev'),
					owl.data('owl-nav-next')
				];
			}

			if (owl.data('owl-single-item')) {
				params['items'] = 1;
			} else {
				params['responsive'] = {
					0: {
						items: owl.data('owl-items-xs'),
					},
					768: {
						items: owl.data('owl-items-sm'),
					},
					992: {
						items: owl.data('owl-items-md'),
					},
					1200: {
						items: owl.data('owl-items-lg'),
					},
					1440: {
						items: owl.data('owl-items-xl'),
					}
				};
			}

			owl.owlCarousel(params);

			if (owl.data('owl-prev')) {
				$(owl.data('owl-prev')).click(function () {
					owl.trigger('prev.owl.carousel');
				});
			}

			if (owl.data('owl-next')) {
				$(owl.data('owl-next')).click(function () {
					owl.trigger('next.owl.carousel');
				});
			}
		});
	}

	function slideMobile() {
		if (!jQuery('body').hasClass('is-mobile')) return;

		var imgItem = jQuery('.header-slideshow .item');
		var imgSingle = jQuery('.header-single-image img');

		// images gallery
		if (imgItem.length > 0) {
			if (jQuery(window).width() > 767) {

				for (i = 0; i < imgItem.length; i++) {
	
					jQuery(imgItem[i]).css("background-image", "url('" + mobileSize.tablet[i] + "')");

				}
			}
		}

		// single image
		if (imgSingle.length > 0) {
			if (jQuery(window).width() > 991) {
				imgSingle.attr("src", mobileSize.tablet[0]);
			}

			if (jQuery(window).width() > 767 && jQuery(window).width() < 992) {
				imgSingle.attr("src", mobileSize.mobile[0]);
			}
		}		
	}

	/*****************************************************************************************/

	function toConsole(string) {
		if ((typeof window.console == "undefined")) {
			alert(string);
		} else console.log(string);
	}

	function $j(argument) {
		var result = jQuery(argument);
		if (result.length === 0) return null;
		else return result;
	}

	function translateCountdown(langCountdown) {

		countdown.resetLabels();

		if (langCountdown == 'it') {
			countdown.setLabels(
				' millisecondo| secondo| minuto| ora| giorno| settimana| mese| anno| decade| secolo| millennio',
				' millisecondi| secondi| minuti| ore| giorni| settimane| mesi| anni| decadi| secoli| millenni',
				' e ',
				' '
			);
		} else if (langCountdown == 'de') {
			countdown.setLabels(
				' Millisekunde| zweite| Minute| jetzt| Tag| Woche| Monat| Jahr| Jahrzehnt| Jahrhundert| Jahrtausend',
				' Millisekunden| Sekunden| Minuten| Stunden| Tage| Wochen| Monate| Alter| Jahrzehnte| Jahrhunderte| Jahrtausende',
				' und ',
				' '
			);
		} else if (langCountdown == 'fr') {
			countdown.setLabels(
				' milliseconde| deuxième| minute| maintenant| jour| semaine| mois| année| décennie| siècle| millénaire',
				' millisecondes| secondes| minutes| heures| journées| semaines| mois| âge| décennies| siècles| millénaires',
				' et ',
				' '
			);
		}

	}

	function edtCountdown() {
		jQuery(".edt_countdown_class").find('.item').each(function () {

			var dataInizio = "";
			var questo = jQuery(this);

			jQuery.post(
				url.ajax_url,
				{
					'action': 'edita_ajax_get_start_countdown_date',
					'offerId': questo.data('offerid'),
				},

				function (response) {
					setInterval(function () {
						stampaCountdown(questo, response);
					}, 3600);
				}
			);
		});
	}

	function stampaCountdown(item, response) {
		jQuery(item).find('.pageTimer').empty().append(
			moment().countdown(response, countdown.DAYS | countdown.HOURS).toString()
		);
	}

	function closePhotoswipe() {
		$('.pswp__button--close').click(function () {
			if ($('.pswp').hasClass('pswp--open')) {
				$('.pswp').removeClass('pswp--supports-fs pswp--open pswp--notouch pswp--css_animation pswp--svg pswp--animated-in pswp--visible pswp--zoom-allowed pswp--has_mouse');
			}
		});

		$(window).scroll(function () {
			if ($('.pswp').hasClass('pswp--open')) {
				$('.pswp').removeClass('pswp--supports-fs pswp--open pswp--notouch pswp--css_animation pswp--svg pswp--animated-in pswp--visible pswp--zoom-allowed pswp--has_mouse');
			}
		});
	}

	/*
	 * Funzione per utilizzare MixItUp da select
	 */
	function filterElements() {
		var container = '.mix-filter-support';

		if ($(container).length) {
			var mixer = mixitup(container, {
				selectors: {
					control: '[data-mixitup-control]'
				}
			});
		}
	}

	/*
	 * Questo oggetto ci serve a verificare il dispositivo utilizzato dall'utente,
	 * la i. nell'espressione regolare sta ad indicare una ricerca case-insensitive,
	 * il metodo nativo test() serve a verificare la presenza della parola nell'espressione regolare,
	 * ritorna un boleano.
	 */
	var mapsMobileUrl = {
		isAndroid: function () {
			return /(android)/i.test(navigator.userAgent);
		},
		isIos: function () {
			return /(iPhone|iPad|iPod)/i.test(navigator.userAgent);
		}
	};

	function injectorLinkMaps() {
		var buttonMaps = $('.dove-siamo-button').find('.navigator');

		if (mapsMobileUrl.isAndroid()) buttonMaps.attr('href', phpMapsMobileUrl.android);
		if (mapsMobileUrl.isIos()) buttonMaps.attr('href', phpMapsMobileUrl.ios);
		if (!mapsMobileUrl.isAndroid() && !mapsMobileUrl.isIos()) buttonMaps.hide();
	}

	function hideContent() {
		var $content = jQuery('.truncate-content');

		if (jQuery(window).width() < 992 && $content.length) {

			if (jQuery(window).width() < 768) {
				linecount = 10;
			}

			var linecount = 6;
			var readmore = 'Leggi tutto';
			var close = 'Chiudi';

			switch (lang) {
				case 'en':
					readmore = 'Read more';
					close = 'Close';
					break;

				case 'de':
					readmore = 'Weiterlesen';
					close = 'Schließen';
					break;

				case 'fr':
					readmore = 'Tout lire';
					close = 'Fermer';
					break;
			}

			$content.moreLines({
				linecount: linecount,
				buttontxtmore: readmore,
				buttontxtless: close,
				animationspeed: 250
			});
		}
	}

	$j(document).ready(function () {

		allClick();
		responsiveTable();
		addImageSizeForPhotoSwiper();
		iframeModalOpen();
		responsiveMenu();
		filterElements();
		injectorLinkMaps();
		hideContent();

		smoothScroll();
		slideMobile();

		closePhotoswipe();

		/*
		if (typeof lang && lang != 'en') {
			translateCountdown(lang);
		}
		*/
		
		edtCountdown();

		// Page regulation

		var page = $('#page'),
			headerHeight = $('#js-header').outerHeight();
		
		page.css('padding-top', headerHeight);

		// Show/hide truncate text

		$('.visite .image').mouseenter(function() {
			$(this).next().find('.content').find('.part-two').addClass('show');
			//$(this).next().find('.content').find('.part-two').removeClass('show');
		  //$( this ).fadeOut( 100 );
		  //$( this ).fadeIn( 500 );
		});

		$('.visite .item').mouseleave(function() {
			$(this).find('.data').find('.content').find('.part-two').removeClass('show');
			//$(this).next().find('.content').find('.part-two').removeClass('show');
		  //$( this ).fadeOut( 100 );
		  //$( this ).fadeIn( 500 );
		});

		/*
		$('.visite .item .content .part-two').on('click', function(e) {
			if($(this).hasClass('show')){
				$(this).removeClass('show');
			}
		});	
		*/

		/*
		$('.open-more').on('click', function(e) {
			e.preventDefault();
			$(this).parent().next().addClass('show');
		});

		$('.close-more').on('click', function(e) {
			e.preventDefault();

			$(this).parent().removeClass('show');
		});
		*/

		// Counter animation

		var windowHeight = $(window).height(),
			counter_visibility = false;
		
		$(window).scroll(function() {

			var currentScrollPos = $(this).scrollTop();

			if ( $('section.numeri').length > 0 ) {
				var counter_offset = $('section.numeri').offset().top;

				if ( !counter_visibility && currentScrollPos > counter_offset - windowHeight + 200 ) {
					counter_visibility = true;

					$('section.numeri .number').each(function () {
						$(this).prop('Counter', 0).animate({
							Counter: $(this).text()
						}, {
							duration: 4000,
							easing: 'swing',
							step: function (now) {
								$(this).text( Math.ceil(now) );
							}
						});
					});
				}
			}

		});

		// Load more posts on click

		$("#loadMore").on('click', function(e) {
			e.preventDefault();
			
			// Init
			var that = $(this);
			var page = that.data('page');
			var newPage = page + 1;
			var ajaxurl = that.data('url');

			// Chiamata AJAX
			$.ajax({
				url: ajaxurl,
				type: 'post',
				data: {
					page: page,
					action: 'ajax_script_load_more'
				},
				error: function(response) {
					console.log(response);
				},
				success: function(response) {
					// Controllo
					if (response == 0) {
						// Controllo
						if ($("#no-more").length == 0) {
							$('#ajax-content').append('<div id="no-more"><h3>Non sono presenti altri articoli!</h3></div>');
						}
						that.hide();
					} else {
						that.data('page', newPage);
						$('#ajax-content').append(response);
					}
				}
			});
		});

		// Change header on scroll (no smartphones)

		if ($(window).width() > 767) {
			$(window).scroll(function () {
				if ($(this).scrollTop() >= 100) {
					$('#js-header').addClass('moved');
				} else {
					$('#js-header').removeClass('moved');
				}
			});
		}

		// Fix for Apple devices

		var ua = navigator.userAgent,
			iOS = /iPad|iPhone|iPod/.test(ua),
			iOS11 = /OS 11_0_1|OS 11_0_2|OS 11_0_3|OS 11_1|OS 11_1_1|OS 11_1_2|OS 11_2|OS 11_2_1/.test(ua);

		// ios 11 bug caret position
		if (iOS && iOS11) {

			// Add CSS class to body
			$("body").addClass("iosBugFixCaret");
		}

		/* Date Picker */

		if ($.datepicker) {
			$.datepicker.setDefaults($.datepicker.regional[lang]);
			jQuery('#arrival, #departure').datepicker({
				beforeShow: function (input) {
					var minDate = 1;
					if (input.id == "departure") {
						minDate = jQuery('#arrival').datepicker("getDate");
						minDate = new Date(minDate.getFullYear(), minDate.getMonth(), minDate.getDate() + 1);
					}

					return {
						minDate: minDate
					}
				}
			});
		}

		/* Cambio messaggio di errore */

		$('#richiesta-preventivo').find('input[required]').on('change invalid', function () {
			var field = $(this).get(0);
			var message = $(this).attr('title');

			field.setCustomValidity('');

			if (!field.validity.valid) {
				field.setCustomValidity(message);
			}
		});

		$('#newsletter').find('input[required]').on('change invalid', function () {
			var field = $(this).get(0);
			var message = $(this).attr('title');

			field.setCustomValidity('');

			if (!field.validity.valid) {
				field.setCustomValidity(message);
			}
		});

		/* Validazione form per browser non HTML5 */

		$('#richiesta-preventivo').submit(function (e) {

			var form = $(this);

			// Integrazione sysdat

			var start = jQuery('#arrivo').datepicker("getDate");
			var end = jQuery('#partenza').datepicker("getDate");
			var diff = end - start;
			var days = diff / 1000 / 60 / 60 / 24;

			$(':input[name="n_notti"]', form).val(days);

			// Gestione formattazione date

			var arrivo = $('#arrivo').val(),
				partenza = $('#partenza').val(),
				separatore = "/";

			var arrDateArrivo = arrivo.split(separatore);
			$('input[name="gg_a"]', form).val(arrDateArrivo[0]);
			$('input[name="mm_a"]', form).val(arrDateArrivo[1]);
			$('input[name="aa_a"]', form).val(arrDateArrivo[2]);

			var arrDatePartenza = partenza.split(separatore);
			$('input[name="gg_p"]', form).val(arrDatePartenza[0]);
			$('input[name="mm_p"]', form).val(arrDatePartenza[1]);
			$('input[name="aa_p"]', form).val(arrDatePartenza[2]);

			// Verifica campi richiesti

			var check = true;
			var element = form.find("[required]");
			var regex = /^[\w\-\.\+]+\@[a-zA-Z0-9\.\-]+\.[a-zA-z0-9]{2,4}$/;

			$('*').removeClass('error');

			$(element).each(function () {
				if ($(this).val() === "") {
					$(this).addClass("error");
					check = false;
				}

				if ($(this).prop('type') === "email" && !regex.test($(this).val())) {
					$(this).addClass("error");
					check = false;
				}

				if ($(this).prop('type') === "checkbox" && !$(this).prop("checked")) {
					$(this).addClass("error");
					check = false;
				}
			});

			if (check) {
				return true;
			} else {
				return false;
			}

		});

		$('#newsletter .edita-form-builder').submit(function (e) {

			var form = $(this);

			// Verifica campi richiesti

			var element = form.find("[required]");
			var regex = /^[\w\-\.\+]+\@[a-zA-Z0-9\.\-]+\.[a-zA-z0-9]{2,4}$/;

			$('*').removeClass('error');

			$(element).each(function () {
				if ($(this).prop('type') === "email" && !regex.test($(this).val())) {
					$(this).addClass("error");
					$(this).focus();
					e.preventDefault();
					return false;
				}

				if ($(this).prop('type') === "checkbox" && !$(this).prop("checked")) {
					$(this).addClass("error");
					$(this).focus();
					e.preventDefault();
					return false;
				}
			});

			//----- Fix per form in https -----
			var action = form.attr('action');
			if (action.indexOf("piumail.com") >= 0 || action.indexOf("editawebmarketing.com") >= 0 || action.indexOf("inemail.it") >= 0) {
				form.attr('action', action.replace("https://", "http://"));
			}
			//---------------------------------

			return true;

		});

		/* Fix per modale con contenuto dinamico */

		$('#feedModal').on('hidden.bs.modal', function (e) {
			$(this).removeData('bs.modal');
			$(".modal-content", this).empty();
		});

		if ($('main .entry-content iframe').length) {

			$('main .entry-content iframe').each(function () {
				$(this).wrap('<div class="responsive-video"></div>');
			});
		}



	});


	$j(window).on('load', function () {

		/*
		 *	Attraverso il Google Loader carico il modulo mappa
		 */
		if ($('body').hasClass('page-template-template-mappa')) {

			$.getScript('https://www.google.com/jsapi', function () {
				if (url.key) {
					var map_params = 'language=' + url.lingua + '&key=' + url.key;
				}
				else {
					var map_params = 'language=' + url.lingua;
				}

				google.load('maps', '3', {
					other_params: map_params + '&libraries=places',
					callback: function () {
						initializeGmap();
					}
				});
			});
		}

		/*
		 * Inizializzazione gallerie per Photoswipe 
		 */
		var gallery = $('.gallery');

		if (gallery.length) {
			gallery.each(function (index) {
				initPhotoSwiper("#" + $(this).attr('id') + " .gallery-item");
			});
		}

		initPhotoSwiper(".entry-content .single-image");

		startOwl('.owl-carousel');


		/*
		 * Gestione altezze uguali 
		 */
		$('section.visite .equal-height').equalHeights();
		$('section.esami .equal-height').equalHeights();
		$('section.specialisti .equal-height').equalHeights();
		$('div.all-news .equal-height').equalHeights();
		
	});

	$j(window).resize(function () {
		
	});

})(jQuery);
